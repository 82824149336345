import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root',
})
export class RolePermissionGuard {
  constructor(private globals: Globals, private router: Router) { }

  adminRoutes = ['admin'];

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.globals.authData) {
      if (this.globals.authData.role_id != 1) {
        if (this.adminRoutes.includes(this.globals.currentLink.split('/')[1])) {
          this.router.navigate(['/']);
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
